<template>
  <div class="row py-2">
    <div class="col-4 d-flex align-items-center">
      <input class="form-check-input me-2 mb-1" type="checkbox" v-model="options.is_diarization">
      <label class="form-check-label mb-0" for="flexCheckDefault">
        Производить диаризацию
      </label>
    </div>

    <div class="col-3 ">
      <label class="visually-hidden" for="num_speakers">Количество говорящих</label>
      <input type="number" class="form-control form-control" id="num_speakers" v-model="options.num_speakers"
             placeholder="Количество говорящих">
    </div>

    <div class="col-5 ">
      <SelectOptions class="w-100" name="Выберите язык"
                     :items="languages"
                     :value="options.language"
                     @update:value="value => options.language = value" />
    </div>

    <div class="col-12 pt-2">
      <label class="visually-hidden" for="initial_prompt">Промпт, кратко описывающий текст</label>
      <input type="text" class="form-control form-control" id="initial_prompt" v-model="options.initial_prompt"
             placeholder="Промпт, кратко описывающий текст">
    </div>
  </div>
</template>

<script>
import {sttLanguageOptions} from "@/data/options";
import SelectOptions from "@/components/reusable/SelectOptions.vue";

export default {
  components: {SelectOptions},
  data() {
    return {
      languages: sttLanguageOptions,
      options: {
        language: 'ru',
      },
    };
  },
  props: {
    chat: Object
  }
}
</script>

<style scoped>

</style>