<template>
  <transition-group name="list" tag="div" class="chat_messages d-flex flex-wrap gap-4 pb-5">
    <div class="w-100" v-for="{ message, role, media, properties, id, parent_id, created_at, message_options, actions, message_formatted, transaction, response, ai_slug, ai_name, chat_id } in messages" :key="id" :id="id">
      <div v-if="IsNotEmpty({ message, role, message_options, media })"
           class="chat_messages__message-block rounded-2 p-3 d-flex flex-column gap-1"
           :class="{
              'message-assistant': role === 'assistant',
              'message-error': role === 'error',
              'message-user': role !== 'assistant' && role !== 'error',
           }">

        <div class="d-flex align-items-center justify-content-between small mb-1">
          <span>{{ formatMessageRole(role, ai_name) }}:</span>
          <span class="fw-light">{{ formatMessageTime(created_at) }}</span>
        </div>

        <div class="mb-1" v-if="message_options">
          <span v-if="message_options.reply_to" >
            В ответ на
            <a class="text-decoration-underline text-decoration-none" role="button" @click="scrollToMessage(message_options.reply_to)">#{{message_options.reply_to}}</a>
          </span>
        </div>

        <div :id="'message_' + id" v-if="response" v-html="formatTranscription(response, id) ?? message"></div>
        <div class="message" v-else-if="message && role === 'assistant'" v-html="formatMessage(message)"></div>
        <div class="message message-wrap" v-else-if="message" v-text="isOriginal[id] || !message_formatted ? message : message_formatted"></div>

        <div>
          <a  v-for="mediaItem in media" :key="mediaItem.uuid" :href="mediaItem['original_url']" data-fancybox data-download="true"
              :data-caption="mediaItem['file_name']" target="_blank" >
            <img v-if="mediaItem.mime_type.includes('image')" class="mt-1 me-1 w-50"
              :src="mediaItem['original_url']" :alt="mediaItem['file_name']" @load="scrollToBottomOnLoad(id)">
          </a>
        </div>

        <div class="mb-1" v-for="mediaItem in media" :key="mediaItem.id">
          <AudioPlayer v-if="mediaItem.mime_type.includes('audio')" :src="mediaItem['original_url']" />
          <video class="w-50" controls v-if="mediaItem.mime_type.includes('video')" :src="mediaItem['original_url']"></video>
        </div>

        <div class="message-block__options" v-if="message_options && role !== 'assistant'">
          <div class="d-flex flex-wrap" v-html="formattedOptions(message_options, ai_slug)"></div>
          <div v-show="message_options.attachments">
            <span role="button" class="text-decoration-underline" @click="loadAttachedFiles(message_options.attachments, id)">Просмтореть прикрепленные файлы</span>
            <div v-show="attachments[id]" class="d-flex flex-wrap">
              <a class="w-100" v-for="file in attachments[id]" :key="file.uuid" :href="file.original_url" target="_blank">{{ file.file_name }}</a>
            </div>
          </div>
        </div>

        <div v-if="placement === 'dialog'">
          <ImageButtons v-if="properties.find(prop => prop.name === 'image_type' && prop.value === 'image')"
                        :messageId="id"
                        :chatId="chat_id"></ImageButtons>
          <ImageSetButtons class="mt-1" v-if="properties.find(prop => prop.name === 'image_type' && prop.value === 'set')"
                           :messageId="id"
                           :chatId="chat_id"></ImageSetButtons>
        </div>

        <div class="d-flex" :class="{
          'justify-content-between' : isVariated(id, parent_id) && role === 'assistant',
          'justify-content-end' : !isVariated(id, parent_id) || role !== 'assistant',
        }">
          <MessageSwitcher
            v-if="role === 'assistant' && placement=== 'dialog'"
            :chatId="chat_id"
            :messageId="id"
            :parentId="parent_id"/>

          <MessageActions
            v-if="placement=== 'dialog' || placement === 'favourites'"
            :role="role"
            :message="message"
            :messageId="id"
            :parentId="parent_id"
            :chatId="chat_id"
            :isLast="id === messages.at(-1).id"
            :actions="actions"
            :formatted="message_formatted"
            :transaction="transaction"
            :has-translation="ai_slug === 'midjourney'"
            :media="media"
            :placement="placement"
            :withSpeakers="withSpeakers[id]"
            :response="response"
            @update:value="isOriginal[id] = $event"/>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
  import MessageSwitcher from "@/components/messages/MessageSwitcher.vue";
  import ImageSetButtons from "@/components/messages/midjourney_buttons/ImageSetButtons.vue";
  import MessageActions from "@/components/messages/MessageActions.vue";
  import ImageButtons from "@/components/messages/midjourney_buttons/ImageButtons.vue";
  import {formatMessage, formattedOptions, formatTime, formatTranscription} from "@/helpers/formatters";
  import {Fancybox} from "@fancyapps/ui";
  import {scrollToBottom} from "@/helpers/interface_helpers";
  import AudioPlayer from "@/components/reusable/AudioPlayer.vue";

  export default {
    components: {
      AudioPlayer,
      MessageSwitcher,
      MessageActions,
      ImageButtons,
      ImageSetButtons,
    },

    data() {
      return {
        isOriginal: [],
        selector: {
          dialog: '.chat_messages',
          public_chat: '.public-chat',
        },
        withSpeakers: [],
        attachments: [],
      }
    },

    props: {
      messages: Array,
      placement: String,
      chatId: Number,
    },

    computed: {
      messagesData() {
        if (!this.chatId) return false;

        return this.$store.state.messagesData[this.chatId];
      },
    },

    beforeMount() {
      Fancybox.bind("[data-fancybox]", {
        Toolbar: {
          display: {
            right: ["download", "thumbs", "close"],
          }
        },
      });
    },

    beforeUnmount() {
      Fancybox.unbind('[data-fancybox]')
    },

    methods: {
      formatMessage(message) {
        return formatMessage(message);
      },

      formatTranscription(data, id) {
        const message =  formatTranscription(data);
        if (message) {
          this.withSpeakers[id] = true;
        }
        return message;
      },

      formattedOptions(options, ai_slug) {
        return formattedOptions(options, ai_slug);
      },

      formatMessageTime(created_at) {
        return formatTime(created_at);
      },

      formatMessageRole(role, aiModel) {
        if (role === 'user') return 'Вы'
        if (role === 'assistant') return aiModel
        if (role === 'error') return 'Ошибка'
        return role
      },

      IsNotEmpty({message, role, message_options, media }) {
        if (role === 'assistant') {
          return true;
        } else if (!message && !message_options && !media) {
          return false;
        }
        return true;
      },

      isVariated(id, parentId) {
        if (this.messagesData) {
          // console.log(this.messagesData.filter(m => m.parent_id === parentId && m.id !== id));
          if(this.messagesData.filter(m => m.parent_id === parentId && m.id !== id).length > 0) return true;
        }
        return false;
      },

      scrollToBottomOnLoad(id) {
        if (this.messages) {
          const messagesWithMedia = this.messages.filter(({media}) => media.length > 0);
          if (id === messagesWithMedia.at(-1).id) {
            scrollToBottom(this.selector[this.placement]);
          }
        }
      },

      scrollToMessage(id, behavior = 'smooth') {
        const message = document.getElementById(id);
        if (message) {
          message.scrollIntoView({behavior, block: 'center'});
        } else {
          if (this.messages.at(0)) {
            const topLoadedMessage = document.getElementById(this.messages.at(0).id);
            if (topLoadedMessage){
              topLoadedMessage.scrollIntoView({behavior});
            }
          }
        }
      },

      async loadAttachedFiles(attachments, id) {
        const response = await this.$store.state.api.getMedias({files_ai_ids: attachments.file_ids});
        this.attachments[id] = await response.data;
      },
    }
  }
</script>
<style scoped>
  .message-block__options {
    color: #4B465C;
  }
</style>