<template>
  <Modal id="filesAvailableFormats" :is-footer-shown="true" modal-name="Поддерживаемые форматы">
    <template #body>
      <div class="files-available-format-container">
        <table class="files-available-format">
          <thead class="border-bottom">
          <tr>
            <th class="files-available-format__item">Формат файла</th>
            <th class="files-available-format__item">Mime-type</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="type in fileTypes[type]" :key="type">
            <th class="files-available-format__item">{{ type.format }}</th>
            <th class="files-available-format__item-right">{{ type.mime_type }}</th>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template #footer>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" :data-bs-target="'#'+ backModalId" data-bs-toggle="modal">Назад</button>
    </template>
  </Modal>
</template>

<script>
  import Modal from "@/components/reusable/Modal.vue";
  import {codeInterpreterMimeTypes, fileSearchMimeTypes} from "@/data/mime_types";

  export default {
    components: { Modal},
    props: {
      type: String,
      backModalId: String,
    },

    data() {
      return {
        fileTypes: {
          code_interpreter: codeInterpreterMimeTypes,
          file_search: fileSearchMimeTypes,
        }
      }
    }
  }
</script>

<style scoped>
  .files-available-format-container {
    height: 595px;
  }

  .files-available-format {
    margin: 0 -24px;
    padding: 0 24px;
    width: calc(100% + 48px);
    border-collapse: collapse;
  }

  .files-available-format thead,
  .files-available-format tbody {
    display: block;
  }

  .files-available-format tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 580px;
    width: 100%;
  }

  .files-available-format__item {
    font-weight: 400;
    padding: 10px 24px;
  }

  .files-available-format__item-right {
    font-weight: 400;
    padding: 10px 10px 10px 97px;
    display: block;
  }
</style>