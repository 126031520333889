<template>
  <div class="d-flex flex-wrap border border-primary rounded p-3 gap-3 w-100">
    <div v-show="withHeader" class="w-100">
      <slot name="header"></slot>
    </div>
    <div class="w-100 d-flex flex-wrap gap-2">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      withHeader: Boolean,
    },
  };
</script>

<style scoped>

</style>