<template>
  <div class="modal fade" :class="modalSizeClass" :id="id" tabindex="-1" :aria-labelledby="id + 'Label'" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">

        <button class="modal__close-button" data-bs-dismiss="modal" role="button" @click.prevent>
          <CloseIcon class="modal__close-button-icon" />
        </button>

        <div class="modal-body">
          <h1 class="modal-body__header pb-2">{{ modalName }}</h1>
          <slot name="body"></slot>
        </div>

        <div v-show="isFooterShown" class="modal-footer">
          <slot name="footer"></slot>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  components: {CloseIcon},
  props: {
    id: String,
    isFooterShown: Boolean,
    modalName: String,
    modalSizeClass: String,
  }
}
</script>

<style scoped>
  .modal {
    background-color: var(--modal-fade-color);
  }

  .modal-body__header {
    font-size: 18px;
    font-weight: 600;
  }

  .modal-content {
    box-shadow: var(--main-shadow-color);
    background-color: var(--block-bg-color);
    color: var(--main-text-color);
  }


  .modal__close-button {
    position: absolute;
    background-color: var(--block-bg-color);
    padding: 6px 7px;
    right: -10px;
    top: -10px;
    box-shadow: var(--main-shadow-color);
    border-radius: 6px;
    z-index: 1;
    border: none;
  }
</style>