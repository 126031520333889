<template>
  <div class="row py-2">
    <div class="col-4">
      <SelectOptions class="w-100" name="Скорость аудио"
                     :items="speeds"
                     :value="options.speed"
                     @update:value="value => options.speed = value" />
    </div>

    <div class="col-4">
      <SelectOptions class="w-100" name="Формат файла"
                     :items="formats"
                     :value="options.format"
                     @update:value="value => options.format = value" />
    </div>

    <div class="col-4">
      <SelectOptions class="w-100" name="Голос"
                     :items="voices"
                     :value="options.voice"
                     @update:value="value => options.voice = value" />
    </div>
  </div>
</template>

<script>
  import {ttsVoicesOptions} from "@/data/options";
  import SelectOptions from "@/components/reusable/SelectOptions.vue";

  export default {
    components: {SelectOptions},
    data() {
      return {
        speeds: ['0.5', '1.0', '1.5', '2.0', ],
        formats: ['mp3', 'opus', 'aac', 'flac', 'wav', 'pcm'],
        voices: ttsVoicesOptions,
        options: {
          voice: 'onyx',
          speed: null,
          format: null,
        },
      }
    }
  }
</script>

<style scoped>

</style>