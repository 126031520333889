<template>
  <Modal modal-name="Прикрепить файлы" :is-footer-shown="true" :id="modalId" modal-size-class="modal-xl">
    <template #body>
      <div class="file-input-menu">
        <ul class="nav nav-underline" v-if="tabs">
          <li  v-for="[tab, tabName] in Object.entries(tabs)" :key="tab" class="nav-item">
            <a class="nav-link" role="button"
               @click="setCurrentTab(tab)"
               :class="{'active': currentTab === tab}">{{ tabName }}</a>
          </li>
        </ul>
      </div>

      <input type="file" ref="fileInput" hidden
             :accept="fileTypes[currentTab].mime_types.join(', ')"
             @change.stop="handleFileUpload">

      <div class="files-table-container" v-if="files.length > 0">
        <table class="files-table">
          <thead class="files-table__header">
          <tr>
            <th class="files-table__item">Файл</th>
            <th class="files-table__item">Размер</th>
            <th class="files-table__item">Формат</th>
            <th class="files-table__item text-center">Корректный формат</th>
            <th class="files-table__item"></th>
          </tr>
          </thead>

          <tbody>
            <tr v-for="file in files" :key="file">
              <th class="files-table__item">{{ file.name }}</th>
              <th class="files-table__item">{{ formatFileSize(file.size) }}</th>
              <th class="files-table__item">{{ file.type ??= file.mime_type }}</th>
              <th class="files-table__item text-center">
                <CheckIcon v-if="fileTypes[currentTab].mime_types.includes(file.type) || fileTypes[currentTab].mime_types.includes(file.mime_type)" />
                <CrossIcon v-else />
              </th>
              <th class="files-table__item text-center">
                <DeleteIcon color="danger" role="button" size="22" @click="deleteFile(file)"/>
              </th>
            </tr>
          </tbody>
        </table>

        <div v-if="loading" class="files-table__loading-container">
          <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :style="{width: loading}"></div>
          </div>
        </div>

        <button v-if="isMultiplyFilesUploadAvailable"
                class="btn btn-outline-primary files-table-container__add-button"
                @click.stop.prevent="loadFile">
          Добавить
          <PlusIcon class="ms-1" />
        </button>
      </div>

      <div class="file-input-space__container" v-else-if="currentTab !== 'image_link'">
        <div class="file-input-space"
             @dragover.prevent
             @drop.prevent="handleFileDrop">
          <div class="file-input-space__loader">

            <LoadFileIcon class="w-100 mb-3"/>

            <div>
              <div v-if="loading">
                <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                  <div class="progress-bar" :style="{width: loading}"></div>
                </div>
              </div>

              <div v-else class="d-flex flex-wrap gap-2">
                <div class="w-100">
                  <button class="btn btn-outline-primary" @click.stop.prevent="loadFile">Загрузить</button>
                </div>
                <div class="w-100 p-1">или перетащите файл сюда</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          Поддерживаемые форматы файлов {{ fileTypes[currentTab].formats.join(', ') }}
          <span v-show="fileTypes[currentTab].hasMore">
          и другие <text role="button" data-bs-target="#filesAvailableFormats" data-bs-toggle="modal" class="text-primary">нажмите, чтобы посмотреть полный список</text>
        </span>
        </div>
      </div>

      <div v-else>
        <label class="form-label" for="image_url">URL - изображения *</label>
        <input type="url" class="form-control" id="image_url" placeholder="Введите" name="image_url" @input="validateUrl" v-model="imageUrl">
      </div>

    </template>
    <template #footer>
      <div v-if="errorMessage" class="small text-danger w-100">{{ errorMessage }}</div>
      <button v-if="!isCancelButtonDisabled" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelAttachment">Отменить</button>
      <button type="button" class="btn btn-primary"
              :disabled="(currentTab === 'image_link' && !isValidURL) || (currentTab !== 'image_link' && files.length === 0)"
              data-bs-dismiss="modal"
              @click="attachFiles">Прикрепить</button>
    </template>
  </Modal>

  <FilesAvailableFormats :type="currentTab" :back-modal-id="modalId" />
</template>

<script>

import Modal from "@/components/reusable/Modal.vue";
import LoadFileIcon from "@/components/icons/LoadFileIcon.vue";
import {codeInterpreterMimeTypes, fileSearchMimeTypes} from "@/data/mime_types";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import CrossIcon from "@/components/icons/CrossIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import FilesAvailableFormats from "@/components/messages/FilesAvailableFormats.vue";
export default {
  components: {
    FilesAvailableFormats,
    PlusIcon,
    DeleteIcon,
    CrossIcon,
    CheckIcon,
    LoadFileIcon,
    Modal,
  },

  props: {
    modalId: String,
    tabs: Array,
    isMessageAttachments: Boolean,
    isCancelButtonDisabled: Boolean,
    isAiMultiplyFilesUploadAvailable: Boolean,
  },

  data() {
    return {
      currentTab: Object.keys(this.tabs)[0],
      imageUrl: '',
      loading: false,
      isValidURL: false,
      errorMessage: null,
      fileTypes: {
        'image': {
          mime_types: ['image/png', 'image/jpeg'],
          formats: ['PNG', 'JPG', 'JPEG'],
        },

        'audio': {
          mime_types: ['audio/mp4', 'audio/ogg', 'audio/webm', 'audio/mpeg'],
          formats: ['MP4', 'OGG', 'WEBM', 'MPEG'],
        },

        'video': {
          mime_types: ['video/mp4', 'video/webm'],
          formats: ['MP4'],
        },

        'code_interpreter': {
          mime_types: codeInterpreterMimeTypes.map(type => type.mime_type),
          formats: ['DOC', 'DOCX', 'JPEG', 'PNG', 'JPEG'],
          hasMore: true,
        },

        'file_search': {
          mime_types: fileSearchMimeTypes.map(type => type.mime_type),
          formats: ['DOC', 'DOCX', 'JPEG', 'PNG', 'JPEG'],
          hasMore: true,
        },

        'image_link': {
          mime_types: [],
        }
      },
    }
  },

  watch: {
    tabs(newValue) {
      this.currentTab = Object.keys(newValue)[0];
    }
  },

  computed: {
    isMultiplyFilesUploadAvailable() {
      return ['code_interpreter', 'file_search'].includes(this.currentTab) || (this.isAiMultiplyFilesUploadAvailable && this.files.length < 4);
    },

    files() {
      return this.$store.state.files[this.currentTab] ? this.$store.state.files[this.currentTab] : [];
    },

    chatId() {
      return this.$store.state.currentChat;
    }
  },

  methods: {
    formatFileSize(size) {
      const sizeInMBs = (size / 1024 / 1024).toFixed(2);
      return `${sizeInMBs} Мб`;
    },

    setCurrentTab(name) {
      this.currentTab = name;
    },

    loadFile() {
      this.$refs.fileInput.click();
    },

    async handleFileUpload() {
      this.loading = '0%';
      const files = Array.from(this.$refs.fileInput.files);

      const progressHandler = (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.loading = percentCompleted + '%';
        if (percentCompleted >= 100) {
          setTimeout(() => {
            this.loading = false;
          });
          this.$store.commit('addFiles', { type: this.currentTab, files });
        }
      };

      const isLoaded = await this.$store.dispatch('uploadFiles', {
        type: this.currentTab,
        files,
        id: this.chatId,
        collection: this.isMessageAttachments ? 'message_' + this.currentTab : this.currentTab,
        config: {
          onUploadProgress: progressHandler
        }
      });

      if (isLoaded) {
        this.loading = false;
      } else {
        this.showErrorMessage('Не удалось загрузить файл');
      }

      this.$refs.fileInput.value = null;
    },

    async handleFileDrop(event) {
      const selectedFiles = event.dataTransfer.files;

      const isLoaded = await this.$store.dispatch('uploadFiles', {
        type: this.currentTab,
        files: Array.from(selectedFiles),
        id: this.chatId,
        collection: this.isMessageAttachments ? 'message_' + this.currentTab : this.currentTab,
      });

      if (!isLoaded) {
        this.showErrorMessage('Не удалось загрузить файл');
      }
    },

    async deleteFile(file) {
      const isDeleted = this.$store.dispatch('deleteFile', {
        type: this.currentTab,
        name: file.name,
        id: this.chatId,
        uuid: file.uuid,
      });
      if (!isDeleted) {
        this.showErrorMessage('Не удалось удалить файл');
      }
    },

    attachFiles() {
      this.$emit("attachFiles", this.currentTab, this.files);
    },

    validateUrl() {
      try {
        const url = new URL(this.imageUrl);
        const validExtensions = ['png', 'jpg', 'jpeg'];
        const extension = url.pathname.split('.').pop().toLowerCase();

        this.isValidURL  = true;

        if (!validExtensions.includes(extension)) {
          this.isValidURL = false;
        }
      } catch (error) {
        this.isValidURL =  false;
      }
    },

    cancelAttachment() {
      this.imageUrl = '';
    },

    showErrorMessage(errorText) {
      this.errorMessage = errorText;

      setTimeout(() => this.errorMessage == null, 3000);
    }
  }
}
</script>

<style scoped>
  .file-input-menu {
    margin: 24px -24px;
    padding: 0 24px;
    border-bottom: 1px solid var(--border-color);
  }

  .nav-link {
    color: var(--main-text-color);
  }

  .file-input-space__container {
    min-height: 400px;
  }

  .file-input-space {
    border:  dashed 2px #30B271;
    border-radius: 6px;
    min-height: 365px;
    margin: 24px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-input-space__loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .file-input-space__loader .w-100 {
    display: flex;
    justify-content: center;
  }

  .active {
    font-weight: 400 !important;
  }

  .files-table-container {
    min-height: 400px;
    border: var(--border-color) 1px solid;
    border-radius: 6px;
    max-height: 200px;
    overflow-y: auto;
  }

  .files-table {
    width: 100%;
    margin-bottom: 65px;
  }

  .files-table__header {
    border-bottom: 1px solid var(--border-color);
  }

  .files-table__item {
    font-weight: 400;
    padding: 10px 24px;
  }

  .files-table-container__add-button {
    position: absolute;
    bottom: 24px;
    margin: 24px;
    background-color: var(--block-bg-color);
  }

  .files-table__loading-container {
    display: flex;
    justify-content: center;
    padding: 16px;
  }

  .progress {
    width: 500px;
  }
</style>