<template>
  <div class="row py-2">
    <div class="col-4">
      <SelectOptions class="w-100" name="Размер изображения"
                     :items="sizes[chat.version.slug]"
                     :value="options.size"
                     @update:value="value => options.size = value" />
    </div>

    <div class="col-4" v-if="chat.version.slug === 'dall-e-3'">
      <SelectOptions class="w-100" name="Качество изображения"
                     :items="qualities"
                     :value="options.quality"
                     @update:value="value => options.quality = value" />
    </div>

    <div class="col-4" v-if="chat.version.slug === 'dall-e-3'">
      <SelectOptions class="w-100" name="Стиль изображения"
                     :items="styles"
                     :value="options.style"
                     @update:value="value => options.style = value" />
    </div>
  </div>
</template>

<script>
import {dalleQualityOptions, dalleStyleOptions} from "@/data/options";
import SelectOptions from "@/components/reusable/SelectOptions.vue";

export default {
  components: {SelectOptions},
    data() {
        return {
            sizes: {
                'dall-e-3': {'1024x1024': '1024x1024', '1792x1024': '1024x1024', '1024x1792': '1024x1024'},
                'dall-e-2': {'256x256': '256x256', '512x512': '512x512', '1024x1024': '1024x1024'}
            },

            qualities: dalleQualityOptions,

            styles: dalleStyleOptions,

            options: {
              size: null,
              quality: null,
              style: null,
            },
        };
    },

    props: {
        chat: Object
    }
}
</script>

<style scoped>

</style>