<template>
  <div class="row py-2">
    <div class="col-6">
      <SelectOptions class="w-100" name="Выберите язык"
        :items="languages"
        :value="options.language"
        @update:value="value => options.language = value" />
    </div>

    <div class="col-6">
      <label class="visually-hidden" for="s_t_t_prompt">Не добавлять в изображение</label>
      <input type="text" class="form-control" id="s_t_t_prompt" v-model="options.prompt"
             placeholder="Описание стиля транскрипции">
    </div>
  </div>
</template>

<script>
import {sttLanguageOptions} from "@/data/options";
import SelectOptions from "@/components/reusable/SelectOptions.vue";

export default {
  components: {SelectOptions},
  data() {
    return {
      languages: sttLanguageOptions,
      options: {
        language: 'ru',
      },
    };
  },
  props: {
    chat: Object
  }
}
</script>

<style scoped>

</style>