<template>
  <div class="row py-2">
    <div class="col-3">
      <SelectOptions class="w-100" name="Размер изображения"
                     :items="sizes"
                     :value="options.size"
                     @update:value="value => options.size = value" />
    </div>

    <div class="col-3">
      <SelectOptions class="w-100" name="Стиль изображения"
                     :items="styles"
                     :value="options.style"
                     @update:value="value => options.style = value" />
    </div>

    <div class="col-6">
      <label class="visually-hidden" for="negative">Негативные промпт</label>
      <input type="text" class="form-control" id="negative" v-model="options.negative"
             placeholder="Исключаемые объекты или свойства (через запятую)">
    </div>

  </div>
</template>

<script>
  import {kandinskyStyleOptions} from "@/data/options";
  import SelectOptions from "@/components/reusable/SelectOptions.vue";

  export default {
    components: {SelectOptions},
    data() {
      return {
        sizes: {'1:1': '1:1', '2:3':'2:3' , '3:2': '3:2', '9:16': '9:16', '16:9': '16:9'},
        styles: kandinskyStyleOptions,
        options: {
          size: null,
          style: null,
        },
      }
    },
  }
</script>
<style scoped>

</style>