<template>
  <div class="dialog-window" v-if="chat.ai">

    <DialogWindowNavbar :chat="chat" :messages="messages" />

    <AssistantNavbar v-if="chat.ai.slug === 'assistant_gpt'" />

    <div class="chat_messages overflow-y-auto p-4 pb-0 mb-auto" v-if="messages" @scrollend="handleScrolling">

      <div class="d-flex flex-wrap gap-4 mb-4">
        <InfoBlock :with-header="true">
          <template #header>
            <span v-text="chat.version.name"></span>
          </template>

          <template #content>
            <span v-html="chat.version.description"></span>
          </template>
        </InfoBlock>

        <InfoBlock :with-header="false">
          <template #content>
          <span class="w-100 d-flex align-items-center gap-1">
          {{ calculationParams.calc_method }}: {{ methodToName[chat.version.calculation_data.calc_method] }}
            <InfoIcon v-if="chat.version.calculation_data.calc_usd_rate"
                      role="button"
                      ref="costInfo"
                      class="cost-info"
                      size="22"
                      data-bs-toggle="popover"
                      data-bs-placement="top"
                      data-bs-custom-class="custom-popover"
                      data-bs-trigger="hover"
                      :data-bs-content="info.cost_terms"/>
        </span>

            <span class="w-100">
              {{ methodToCostName[chat.version.calculation_data.calc_method] }}:
              <span class="rouble-symbol">₽</span>
              {{ chat.version.calculation_data.calc_input_price }}
            </span>

            <span class="w-100" v-if="chat.version.calculation_data.calc_output_price">
          {{ calculationParams.calc_output_price }}: <span class="rouble-symbol">₽ </span>{{ chat.version.calculation_data.calc_output_price }}
        </span>
          </template>
        </InfoBlock>
      </div>

      <div class="text-center mt-5" v-if="messages.length === 0">
        Нет сообщений
      </div>

      <Messages :messages="messages" placement="dialog" :chat-id="chat.id"/>

      <LoadingProcess v-show="loading && !loadingTranslate && !loadingThirdPartyService"/>

      <div v-show="loadingTranslate" class="text-secondary text_loading my-3">
        {{ info.translating_process }}
      </div>

      <div v-show="loadingThirdPartyService" class="text-secondary text_loading my-3">
        {{ info.loading_third_party_service }}
      </div>

      <div v-show="showAlert" class="alert alert-danger text-danger" role="alert">
        {{ alertMessage }}
      </div>
    </div>

    <div v-show="showMessageAction"
         class="alert alert-success position-absolute end-0 mx-5 margin-top-80 message-action-alert" role="alert">
      {{ showMessageAction }}
    </div>

    <div class="text-center my-2 text-danger" v-if="!chat.version.is_active || !chat.ai.is_active">
      {{ info.unavailable_ai }}
    </div>

    <div v-else class="dialog-window__message-form-container">
      <form @submit="sendMessage" @keydown.exact.ctrl.enter="sendMessage" @keydown.exact.meta.enter="sendMessage">
        <div class="dialog-window__message-form d-flex flex-wrap py-3 px-4">
          <div class="has-validation w-100 d-flex flex-nowrap gap-2">
            <button class="btn btn-outline-settings"
                    :disabled="!isFileInputAvailable"
                    type="button"
                    :data-bs-target="'#' + filesModalId"
                    data-bs-toggle="modal">
              <PaperClipIcon />
            </button>

            <textarea class="form-control" id="messageInput" :class="{'is-invalid': errors.message }"
                      placeholder="Сообщение"
                      :disabled="!(chat.ai.slug !== 'speech_to_text' && chat.ai.slug !== 'offline_speech_to_text' && chat.version.slug !== 'describe')"
                      aria-describedby="errorFeedback"
                      oninput="this.style.height='';this.style.height=this.scrollHeight+'px'"
                      v-model="newMessage" rows="1"
                      @paste="handlePaste"></textarea>

            <button class="btn"
                    :class="{'btn-outline-settings': !isShowOptions, 'btn-outline-settings-active': isShowOptions}"
                    type="button" @click="isShowOptions=!isShowOptions"
                    v-show="isOptionsAvailable">
              <SettingsIcon />
            </button>

            <button class="btn btn-primary" type="submit"
                    :disabled="loading || loadingTranslate || loadingThirdPartyService">
              Отправить
            </button>
          </div>
          <div class="text-danger mt-2" id="errorFeedback">{{ errors.message }}</div>
        </div>

        <FilesAttachments v-if="isFileInputAvailable" ref="attachments"
                          :modal-id="filesModalId"
                          :tabs="fileTabs[chatVersionSlug]"
                          :is-message-attachments="true"
                          @attach-files="attachFiles"
                          :is-ai-multiply-files-upload-available="isAiMultiplyFilesUploadAvailable"
                          @delete-files="deleteFiles"/>

        <div v-if="files['image'].length > 0" class="mx-4 py-3 d-flex flex-wrap align-items-center gap-1">
          <div class="d-flex align-items-center gap-1 w-100" v-for="file in files['image']" :key="file">
            <ImageFileIcon />
            <span>{{ file.name }}</span>
            <CrossIcon role="button" @click="deleteFiles('image', file.name)" />
          </div>
        </div>

        <div v-if="files['audio'].length > 0" class="mx-4 py-3 d-flex flex-wrap align-items-center gap-1">
          <div class="d-flex align-items-center gap-1 w-100" v-for="file in files['audio']" :key="file">
            <AudioFileIcon />
            <span>{{ file.name }}</span>
            <CrossIcon role="button" @click="deleteFiles('audio', file.name)" />
          </div>
        </div>

        <div v-if="files['video'].length > 0" class="mx-4 py-3 d-flex flex-wrap align-items-center gap-1">
          <div class="mx-4 py-3 d-flex align-items-center gap-1" v-for="file in files['video']" :key="file">
            <AudioFileIcon />
            <span>{{ file.name }}</span>
            <CrossIcon role="button" @click="deleteFiles('video', file.name)" />
          </div>
        </div>

        <div class="form-check mx-4 py-3" v-if="chat.ai.slug === 'midjourney' && chat.version.slug !== 'describe'">
          <input class="form-check-input" type="checkbox" v-model="isNeedToTranslate" id="flexCheckDefault">
          <label class="form-check-label ms-1" for="flexCheckDefault">
            Автоматически переводить промпт на английский
          </label>
        </div>

        <div class="mx-4 my-2" v-show="isShowOptions">
          <div v-if="chat.ai.slug === 'dalle'">
            <DalleOptions :chat="chat" ref="dalle"/>
          </div>

          <div v-if="chat.ai.slug === 'midjourney'">
            <MidjourneyOptions :chat="chat" ref="midjourney"/>
          </div>

          <div v-if="chat.ai.slug === 'speech_to_text'">
            <SpeechToTextOptions :chat="chat" ref="speech_to_text"/>
          </div>

          <div v-if="chat.ai.slug === 'offline_speech_to_text'">
            <OfflineSpeechToTextOptions :chat="chat" ref="offline_speech_to_text"/>
          </div>

          <div v-if="chat.ai.slug === 'kandinsky'">
            <KandinskyOptions ref="kandinsky"/>
          </div>

          <div v-if="chat.version.slug === 'gpt-4-vision-preview' || chat.version.slug === 'gpt-4o'">
            <ChatGPTOptions ref="vision"/>
          </div>

          <div v-if="chat.ai.slug === 'text_to_speech'">
            <TextToSpeechOptions ref="tts"/>
          </div>

          <div v-if="chat.ai.slug === 'yandex_gpt'">
            <YandexGPTOptions ref="yandex_gpt"/>
          </div>

          <div v-if="chat.ai.slug === 'assistant_gpt'">
            <AssistantGPTOptions ref="assistant_gpt" :chat="chat"/>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DalleOptions from "@/components/messages/options/DalleOptions.vue";
import MidjourneyOptions from "@/components/messages/options/MidjourneyOptions.vue";
import SpeechToTextOptions from "@/components/messages/options/SpeechToTextOptions.vue";
import OfflineSpeechToTextOptions from "@/components/messages/options/OfflineSpeechToTextOptions.vue";
import LoadingProcess from "@/components/reusable/LoadingProcess.vue";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import {calculationParams, methodToCostName, methodToName} from "@/data/calculation";
import KandinskyOptions from "@/components/messages/options/KandinskyOptions.vue";
import ChatGPTOptions from "@/components/messages/options/ChatGPTOptions.vue";
import TextToSpeechOptions from "@/components/messages/options/TextToSpeechOptions.vue";
import {Popover} from 'bootstrap';
import Messages from "@/components/messages/MessagesList.vue";
import AssistantGPTOptions from "@/components/messages/options/AssistantGPTOptions.vue";
import {scrollToBottom} from "@/helpers/interface_helpers";
import DialogWindowNavbar from "@/components/messages/DialogWindowNavbar.vue";
import InfoBlock from "@/components/messages/InfoBlock.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import PaperClipIcon from "@/components/icons/PaperClipIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import FilesAttachments from "@/components/messages/FilesAttachments.vue";
import ImageFileIcon from "@/components/icons/ImageFileIcon.vue";
import CrossIcon from "@/components/icons/CrossIcon.vue";
import AudioFileIcon from "@/components/icons/AudioFileIcon.vue";
import {fileTabs} from "@/data/file_tabs";
import AssistantNavbar from "@/components/messages/AssistantNavbar.vue";
import {aiVersionAvailableOptions} from "@/data/ai_version_available_options";
import YandexGPTOptions from "@/components/messages/options/YandexGPTOptions.vue";


export default {
  components: {
    YandexGPTOptions,
    AssistantNavbar,
    AudioFileIcon,
    CrossIcon,
    ImageFileIcon,
    FilesAttachments,
    SettingsIcon,
    PaperClipIcon,
    InfoIcon,
    InfoBlock,
    DialogWindowNavbar,
    AssistantGPTOptions,
    Messages,
    TextToSpeechOptions,
    ChatGPTOptions,
    KandinskyOptions,
    LoadingProcess,
    DalleOptions,
    MidjourneyOptions,
    SpeechToTextOptions,
    OfflineSpeechToTextOptions,
  },
  data() {
    return {
      chat: [],
      errors: {},
      newMessage: '',
      isShowOptions: false,
      isJustOpened: true,
      files: {
        image: [],
        audio: [],
        video: [],
        code_interpreter: [],
        file_search: [],
      },
      isNeedToTranslate: true,
      fileInputNames: {
        midjourney: 'Изображения-подсказка',
        chatgpt: 'Прикрепить изображение'
      },
      info: {
        cost_terms: 'Стоимость сообщения может меняться в зависимости от текущего курса доллара',
        loading_third_party_service: 'Сообщение принято. Ожидаем ответа от нейросети . . .',
        translating_process: 'Переводим промпт . . .',
        unavailable_ai: 'В данный момент нейросеть недоступна для использования',
      },

      filesModalId: 'file_attachments',
      fileTabs: fileTabs,

      options: {},

      aiVersionAvailableOptions,
    };
  },

  props: {
    chatId: String,
  },

  computed: {
    methodToCostName() {
      return methodToCostName
    },

    methodToName() {
      return methodToName
    },

    calculationParams() {
      return calculationParams
    },

    loading() {
      const lastMessage = this.messages ? this.messages.at(-1) : null;
      const isLastMessageUser = lastMessage ? lastMessage.role === 'user' : false;
      return (this.$store.state.loading[this.chat.id] && isLastMessageUser) ||
        (this.$store.state.loading[this.chat.id] && this.$store.state.rerenderLoading[this.chat.id]);
    },

    loadingTranslate() {
      return this.$store.state.loadingTranslate[this.chat.id];
    },

    loadingThirdPartyService() {
      return this.$store.state.loadingThirdPartyService[this.chat.id];
    },

    messages() {
      return this.$store.state.messages[this.chat.id];
    },

    showAlert() {
      return this.$store.state.showAlert[this.chat.id];
    },

    alertMessage() {
      return this.$store.state.alertMessages[this.chat.id];
    },

    showMessageAction() {
      return this.$store.state.showMessageAction[this.chat.id];
    },

    variants() {
      return this.$store.state.variants[this.chat.id];
    },

    messageNotResponded() {
      const lastMessage = this.$store.state.messages[this.chat.id].at(-1);
      if (!lastMessage) return false;
      return lastMessage.role === 'user' && !lastMessage.properties.find(prop => prop.name === 'sending_failed');
    },

    isOptionsAvailable() {
      return this.aiVersionAvailableOptions.includes(this.chat.version.slug);
    },

    isFileInputAvailable() {
      return  Object.keys(this.fileTabs[this.chat.version.slug] ?? []).length !== 0;
    },

    isAiMultiplyFilesUploadAvailable() {
      return this.chat.ai.slug === 'midjourney' && this.chat.version.slug !== 'describe';
    },

    chatVersionSlug() {
      const chat = this.$store.state.chats.data.find((chat) => this.chat.id === chat.id);
      if (chat) {
        return chat.version.slug;
      }
      return this.chat.version.slug;
    },
  },

  async beforeMount() {
    const response = await this.$store.dispatch('getChat', this.chatId);
    this.chat = await response.data;
    await this.$store.dispatch('getChatMessages', this.chat.id);
    await this.$store.dispatch('openChannel', this.chat.id);

    if (this.chat.has_not_seen_messages) {
      await this.$store.state.api.updateMessage(this.chat.id, this.messages.at(-1).id, { message_seen: true });
      await this.$store.commit('setNewMessageReceived', {id: this.chat.id, value: false});
    }

    if (this.chat.ai.slug === 'offline_speech_to_text' && this.messageNotResponded) {
      this.$store.commit('setThirdPartyServiceLoading', {id: this.chat.id, value: true});
    }

    this.$store.commit('addFiles', {
      type: 'code_interpreter',
      files: this.chat.media.filter(({collection_name}) => collection_name === 'message_code_interpreter'),
    });
    this.$store.commit('addFiles', {
      type: 'file_search',
      files: this.chat.media.filter(({collection_name}) => collection_name === 'message_file_search'),
    });

    if (this.$route.query.message_id) {
      await this.$store.dispatch('loadSearchingMessage', {
        chatId: this.chat.id,
        messageId: this.$route.query.message_id,
      });
      if (await this.messages) {
        await this.scrollToMessage(this.$route.query.message_id);
      }
      await this.highlightMessage(this.$route.query.message_id);
    } else {
      await scrollToBottom();
    }
  },

  async mounted() {
    setTimeout(() => this.initializePopovers(), 1000);
    this.resetMessageInputHeight();
  },

  async beforeUnmount() {
    await this.$store.dispatch('closeChannel', this.chat.id);
    await this.$store.commit('deleteChatMessages', this.chat.id);
    await this.$store.commit('clearFiles');
  },

  methods: {
    initializePopovers() {
      if (this.$refs.costInfo) {
        new Popover(document.querySelector('.cost-info'), {
          html: true,
          delay: {"show": 300, "hide": 50},
        });
      }
    },

    resetMessageInputHeight() {
      const input = document.getElementById('messageInput')
      if (input) input.style.height = ''
    },

    scrollToMessage(id, behavior = 'smooth') {
      const message = document.getElementById(id);
      if (message) {
        message.scrollIntoView({behavior, block: 'center'});
      } else {
        if (this.messages.at(0)) {
          const topLoadedMessage = document.getElementById(this.messages.at(0).id);
          if (topLoadedMessage) {
            topLoadedMessage.scrollIntoView({behavior});
          }
        }
      }
    },

    async handleScrolling() {
      const container = document.querySelector(".chat_messages");
      if (container) {
        if (container.scrollTop === 0) {
          const lastMessageId = await this.$store.dispatch('getMoreChatMessages', this.chat.id);
          if (lastMessageId) this.scrollToMessage(lastMessageId, 'instant');
        }
      }
    },

    highlightMessage(id) {
      const message = document.getElementById(id);
      if (message) {
        message.classList.add('highlight-animation');

        message.addEventListener('animationend', () => {
          message.classList.remove('highlight-animation');
        }, {once: true});
      }
    },

    handlePaste(e) {
      if (e.clipboardData.items) {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);

            this.files['image'] = Array.from(dataTransfer.files);
          }
        }
      }
    },

    async sendMessage(e) {
      if (this.loading) {
        return;
      }

      this.errors.message = null;
      e.preventDefault();

      let message = {};
      if (this.chat.ai.slug === 'chatgpt') {
        if (this.chat.version.slug === 'gpt-4-vision-preview' || this.chat.version.slug === 'gpt-4o') {
          message = new FormData();

          if (this.newMessage) {
            message.append('message', this.newMessage);
          }

          if (this.$refs.vision.options.detail) {
            message.append('options[detail]', this.$refs.vision.options.detail);
          }

          if (this.$refs.attachments.imageUrl) {
            message.append('image_url', this.$refs.attachments.imageUrl);
          }
          else if (this.files['image']) {
            this.files['image'].forEach((file) => message.append('image[]', file));
          }
        } else {
          message.message = this.newMessage;
        }
      }

      if (this.chat.ai.slug === 'dalle') {
        message.message = this.newMessage;
        message.options = this.optionsToArray(this.$refs.dalle.options);
      }

      if (this.chat.ai.slug === 'midjourney') {
        message = new FormData();
        if (this.newMessage) {
          message.append('message', this.newMessage);
        }

        const options = this.optionsToArray(this.$refs.midjourney.options);
        Object.keys(options).reduce((acc, prop) => message.append(`options[${prop}]`, options[prop]), []);
        if (this.files['image']) {
          this.files['image'].forEach((file) => message.append('image[]', file));
        }
        message.append('formatting[translate]', Number(this.isNeedToTranslate));
        if (this.isNeedToTranslate && this.chat.version.slug !== 'describe') {
          this.$store.commit('setLoadingTranslate', {id: this.chat.id, value: true});
          scrollToBottom();
        }

        if (this.chat.version.slug === 'describe') {
          message = new FormData();
          if (this.files['image'].length > 0) {
            message.append('image[]', this.files['image'][0]);
          }
        }
      }

      if (this.chat.ai.slug === 'speech_to_text') {
        message = new FormData();
        const files = this.files['audio'] ? this.files['audio'] : this.files['video'];
        if (!files) {
          this.errors.message = 'Не прикреплены файлы';
          return;
        }
        message.append('audio', files[0]);

        if (this.$refs.speech_to_text.options.prompt) {
          message.append('options[prompt]', this.$refs.speech_to_text.options.prompt)
        }
        if (this.$refs.speech_to_text.options.language) {
          message.append('options[language]', this.$refs.speech_to_text.options.language)
        }
      }

      if (this.chat.ai.slug === 'offline_speech_to_text') {
        message = new FormData();
        const files = this.files['audio'] ? this.files['audio'] : this.files['video'];
        if (!files) {
          this.errors.message = 'Не прикреплены файлы';
          return;
        }
        message.append('audio', files[0]);

        if (this.$refs.offline_speech_to_text.options.language) {
          message.append('options[language]', this.$refs.offline_speech_to_text.options.language);
        }

        if (this.$refs.offline_speech_to_text.options.initial_prompt) {
          message.append('options[initial_prompt]', this.$refs.offline_speech_to_text.options.initial_prompt);
        }

        if (this.$refs.offline_speech_to_text.options.is_diarization) {
          message.append('options[is_diarization]', 'true');
        }

        if (this.$refs.offline_speech_to_text.options.num_speakers) {
          message.append('options[num_speakers]', this.$refs.offline_speech_to_text.options.num_speakers);
        }
      }

      if (this.chat.ai.slug === 'assistant_gpt') {
        message = new FormData();

        if (this.newMessage) {
          message.append('message', this.newMessage);
        }

        if (this.files['image']) {
          this.files['image'].forEach((file) => message.append('image[]', file));
        }

        if (this.options.attachments) {
          message.append('options[attachments][tool]', this.options.attachments.tool);
          this.options.attachments.file_ids.forEach((id) => message.append('options[attachments][file_ids][]', id));
        }
      }

      if (this.chat.ai.slug === 'kandinsky') {
        message.message = this.newMessage;
        message.options = this.optionsToArray(this.$refs.kandinsky.options);
      }

      if (this.chat.ai.slug === 'text_to_speech') {
        message.message = this.newMessage;
        message.options = this.optionsToArray(this.$refs.tts.options);
      }

      if (this.chat.ai.slug === 'yandex_gpt') {
        message.message = this.newMessage;
        message.options = {};
        if (this.$refs.yandex_gpt.options.max_tokens) {
          message.options.max_tokens = this.$refs.yandex_gpt.options.max_tokens;
        }
        if (this.$refs.yandex_gpt.options.temperature) {
          message.options.temperature = this.$refs.yandex_gpt.options.temperature;
        }
        if (this.$refs.yandex_gpt.options.async_mode) {
          message.options.async_mode = 1;
        }
      }

      if (this.messages.length !== 0) {
        if (message instanceof FormData) {
          message.append('parent_id', this.messages.at(-1).id);
        } else {
          message.parent_id = this.messages.at(-1).id;
        }
      }

      this.$store.commit('setLoading', {value: true, id: this.chat.id});
      await scrollToBottom();
      const newMessage = await this.$store.dispatch('sendMessage', {id: this.chat.id, message});
      await this.$store.commit('setLoadingTranslate', {id: this.chat.id, value: false});
      await this.$store.commit('setChatFirst', {id: this.chat.id, model: this.chat.ai.slug});
      await this.$emit('new-message-received', 0);
      // eslint-disable-next-line no-prototype-builtins
      if (newMessage.hasOwnProperty('error')) {
        await this.$store.commit('setLoading', {value: false, id: this.chat.id});
        if (newMessage.balance_error) {
          await this.$store.commit('setAlert', {value: true, id: this.chat.id, message: newMessage.error});
          setTimeout(() => this.$store.commit('setAlert', {value: false, id: this.chat.id}), 7000);
        } else {
          this.errors.message = await newMessage.error === '' ? 'Ошибка при валидации данных' : await newMessage.error;
        }
      } else {
        await this.clearForm();
        await this.resetMessageInputHeight();
        await scrollToBottom();
        await this.$store.commit('clearTemporaryFiles');
      }
    },

    clearForm() {
      this.newMessage = '';

      if (this.$refs.midjourney) {
        this.$refs.midjourney.options = [];
      }

      if (this.$refs.dalle) {
        this.$refs.dalle.options = [];
      }

      if (this.$refs.kandinsky) {
        this.$refs.kandinsky.options = [];
      }

      if (this.$refs.offline_speech_to_text) {
        this.$refs.offline_speech_to_text.options = {language: 'ru'};
      }

      if (this.$refs.assistantAttachments) {
        this.$refs.assistantAttachments.options = null;
      }

      if (this.$refs.attachments) {
        this.$refs.attachments.imageUrl = '';
      }
      this.files = {
        image: [],
        audio: [],
        video: [],
        code_interpreter: [],
        file_search: [],
      };

      this.options.attachments = null;
    },

    async attachFiles(type, files) {
      if (type === 'code_interpreter' || type === 'file_search') {
        this.options.attachments = {
          tool: type,
          file_ids: files.map(file => file.custom_properties.ai_id),
        };
      }

      else {
        this.files[type] = files;
      }
    },

    deleteFiles(type,name) {
      this.files[type] = this.files[type].filter(file => file.name !== name);
    },

    optionsToArray(options) {
      const targets = ['quality', 'stop', 'stylize', 'no', 'style', 'size', 'negative', 'voice', 'format', 'speed'];
      const formatted = {};
      for (let target of targets) {
        if (options[target]) {
          formatted[target] = options[target];
        }
      }
      return formatted;
    },
  }
}
</script>

<style scoped>
.dialog-window {
  background-color: var(--block-bg-color);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-top: 1.65rem;
}

.dialog-window__message-form-container {
  border-top: 1px solid var(--border-color);
}

.dialog-window__message-form {
  border-bottom: 1px solid var(--border-color);
}

.margin-top-80 {
  margin-top: 80px;
}

.message-action-alert {
  animation: ani 0.3s forwards;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text_loading {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 40%;
  }
}

#messageInput {
  max-height: 200px;
}
</style>