<template>
  <div class="authorization-template">
    <div class="authorization-template__background">
      <div class="authorization-template__bg">

      </div>
      <div class="authorization-template__container">
        <div class="authorization-template__router">
          <slot name="router"></slot>
        </div>

        <div class="authorization-template__header">
          <slot name="header"></slot>
        </div>

        <div class="authorization-template__subheader">
          <slot name="subheader"></slot>
        </div>

        <div class="authorization-template__form">
          <slot name="form"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style>
  .authorization-template {
    height: 100vh;
    padding: 32px;
    width: 100%;
    background-color: var(--block-bg-color);
  }

  .authorization-template__background {
    background-color: var(--main-bg-color);
    padding: 40px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: end;
    border-radius: 12px;
  }

  .authorization-template__bg {
    background-image: url("@/assets/images/auth_bg_image.png");
    width: 919px;
    height: 702px;
    position: absolute;
    right: 25%;
    top: 20%;
    opacity: 20%;
    background-size: contain;
    z-index: 0;
    max-width: 70%;
    max-height: 60%;
  }

  .authorization-template__container {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--block-bg-color);
    width: 550px;
    border-radius: 12px;
    box-shadow: var(--bs-box-shadow);
    padding: 120px 65px;
    justify-content: center;
    align-content: start;
    z-index: 1;
  }

  .authorization-template__router {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 16px;
  }

  .authorization-template__header {
    width: 100%;
    text-align: center;
    font-size: 26px;
    padding-top: 62px;
    line-height: 36px;
  }

  .authorization-template__subheader {
    width: 100%;
    text-align: center;
    padding-top: 7px;
  }

  .authorization-template__form {
    width: 100%;
    margin-top: 28px;
  }

  .router__btn {
    color: rgba(168, 170, 174, 1) !important;
  }

  .router__btn-active {
    background-color: rgba(48, 178, 113, 0.16) !important;
    color: var(--primary-color) !important;
  }
</style>