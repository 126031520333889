<template>
  <div class="message_actions d-flex flex-nowrap gap-4 align-items-end mt-2">
    <div>
      <button type="button" v-if="placement === 'favourites'" class="btn btn-sm btn-outline-primary" @click="goToChat(chatId, messageId)">Показать в чате</button>
      <button type="button" v-if="role === 'user' && hasTranslation && message && formatted" class="btn btn-outline-dark rounded-5 py-0 px-2"
              v-text="isOriginal ? 'Показать перевод' : 'Показать оригинал'"
              @click="switchOriginalTranslate"></button>
    </div>
    <div>
      <ClipboardIcon role="button"
                     v-if="role === 'assistant' && withSpeakers"
                     @click="switchTranscriptionDisplayFormat" />

      <DownloadIcon role="button"
                    v-if="role === 'assistant' && media.length > 0"
                    @click="downloadMediaFile" />

      <LikeIcon role="button"
                v-if="role === 'assistant'"
                :active="liked"
                @click="like" />

      <DislikeIcon role="button"
                   v-if="role === 'assistant'"
                   :active="disliked"
                   @click="dislike" />

      <ResendIcon role="button"
                  v-if="role === 'assistant' && isAiActive"
                  :class="{'rerender_action_disabled': loading}"
                  @click="sendAgain" />

      <FavouriteIcon role="button"
                     v-if="placement !== 'favourites'"
                     size="20"
                     :active="favourite"
                     @click="addToFavourites" />

      <CopyIcon role="button"
                v-if="message && !copying"
                @click="copy" />

      <CopyDoneIcon
                    v-if="copying" />

      <InfoIcon role="button"
                v-if="role === 'assistant'"
                size="20"
                :data-bs-target="'#infoMessage' + messageId"
                data-bs-toggle="modal" />

      <DeleteIcon role="button"
                  v-if="placement === 'favourites'"
                  size="20"
                  color="default"
                  @click="addToFavourites" />
    </div>

    <Modal :id="'infoMessage' + messageId" modal-name="Информация">
      <template #body>
        <div v-if="transaction">
          <span v-for="[key, value] in Object.entries(transaction.calculation)" :key="key">
            {{transactionsParams[key]}}: {{value}}
            <span v-if="transaction.prices[key]">по {{transaction.currency}}{{transaction.prices[key]}} за {{priceToUnits[key]}}</span>
            <span v-if="key === 'rate'"> руб.</span>
            <br>
          </span>
          <span v-if="transaction.prices.response_price">Генерация ответа: 1 ответ по {{transaction.currency}}{{transaction.prices.response_price}}<br></span>
          <span>Стоимость: {{Math.abs(transaction.amount)}} руб.</span>
        </div>
        <div class="modal-body" v-else>
          Нет информации о стоимости сообщения.
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
import {transactionsParams} from "@/data/transactions";
import {priceToUnits} from "@/data/calculation";
import {formatTranscription} from "@/helpers/formatters";
import {scrollToBottom} from "@/helpers/interface_helpers";
import Modal from "@/components/reusable/Modal.vue";
import ClipboardIcon from "@/components/icons/ClipboardIcon.vue";
import DownloadIcon from "@/components/icons/DownloadIcon.vue";
import LikeIcon from "@/components/icons/LikeIcon.vue";
import DislikeIcon from "@/components/icons/DislikeIcon.vue";
import ResendIcon from "@/components/icons/ResendIcon.vue";
import FavouriteIcon from "@/components/icons/FavouriteIcon.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import CopyDoneIcon from "@/components/icons/CopyDoneIcon.vue";

export default {
  components: {
    CopyDoneIcon,
    DeleteIcon,
    InfoIcon,
    CopyIcon,
    FavouriteIcon,
    ResendIcon,
    DislikeIcon,
    LikeIcon,
    DownloadIcon,
    ClipboardIcon,
    Modal,
  },
    data() {
      return {
        liked: null,
        disliked: null,
        favourite: null,
        copying: false,
        isOriginal: false,
        isDefaultDisplayFormat: false,
      }
    },

    props: {
      role: String,
      message: String,
      messageId: Number,
      chatId: Number,
      // eslint-disable-next-line vue/require-prop-type-constructor
      parentId: Number|String,
      actions: Object,
      isLast: Boolean,
      hasTranslation: Boolean,
      formatted: String,
      transaction: Object,
      placement: String,
      media: Array,
      withSpeakers: Boolean,
      response: Object,
    },

    mounted() {
      this.liked = this.actions.find(({type}) => type === 'like');
      this.disliked = this.actions.find(({type}) => type === 'dislike');
      this.favourite = this.actions.find(({type}) => type === 'favourite');
      this.params = {
        chatId: this.chatId,
        messageId: this.messageId,
      };
    },

    beforeUnmount() {
      this.$store.commit('setRerenderLoading', {value: false, id: this.chatId});
    },

    computed: {
      priceToUnits() {
        return priceToUnits;
      },

      transactionsParams() {
        return transactionsParams;
      },

      loading() {
        return this.$store.state.loading[this.chatId];
      },

      isAiActive() {
        return this.$store.getters.isAiActive;
      }
    },

    methods: {
      switchTranscriptionDisplayFormat() {
        document.querySelector('#message_' + this.messageId).innerHTML = formatTranscription(this.response, this.isDefaultDisplayFormat);
        this.isDefaultDisplayFormat = !this.isDefaultDisplayFormat;
      },

      switchOriginalTranslate() {
        this.isOriginal = !this.isOriginal;
        this.$emit('update:value', this.isOriginal);
      },

      copy() {
        if (this.isOriginal || !this.formatted) {
          navigator.clipboard.writeText(this.message);
        }
        else {
          navigator.clipboard.writeText(this.formatted);
        }
        this.copying = true;
        setTimeout(() => this.copying = false, 2000)
      },

      downloadMediaFile() {
        this.media.forEach((item) => {
          const anchor = document.createElement("a");
          anchor.href = item['original_url'];
          anchor.download = item['original_url'];
          anchor.style.display = 'none';

          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        });
      },

      async like() {
        if (this.liked) {
          this.$store.dispatch('deleteAction', { ...this.params, actionId: this.liked.id, removeAction: 'like' });
          this.liked = false;
        } else {
          this.liked = await this.$store.dispatch('storeAction', { ...this.params, action: 'like', removeAction: 'dislike' });
          this.disliked = false;
        }
      },

      async dislike() {
        if (this.disliked) {
          this.$store.dispatch('deleteAction', { ...this.params, actionId: this.disliked.id, removeAction: 'dislike' });
          this.disliked = false;
        } else {
          this.disliked = await this.$store.dispatch('storeAction', { ...this.params, action: 'dislike', removeAction: 'like' });
          this.liked = false;
        }
      },

      async addToFavourites() {
        if (this.favourite) {
          this.$store.dispatch('deleteAction', { ...this.params, actionId: this.favourite.id, removeAction: 'favourite' });
          this.favourite = false;
        } else {
          this.favourite = await this.$store.dispatch('storeAction', { ...this.params, action: 'favourite' });
        }
      },

      async sendAgain() {
        await this.$store.dispatch('storeAction', {
          chatId: this.chatId,
          messageId: this.parentId,
          action: 'regenerate'
        });
        await this.$store.commit('setLoading', {value: true, id: this.chatId});
        await this.$store.commit('setRerenderLoading', {value: true, id: this.chatId});
        await scrollToBottom();
      },

      async goToChat(chat_id, message_id) {
        this.$router.push({name: 'Chats', params: {chat_id}, query: { message_id }});
      },
    }
  }
</script>

<style scoped>
  .message_actions svg {
    transition: .5s;
  }

  .rerender_action_disabled {
    pointer-events: none;
  }
</style>