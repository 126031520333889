<template>
  <div class="btn-group">
    <div ref="selectElement" class="form-select form-select__nowrap-container"
         :class="{ 'form-select-fixed': Object.entries(items).length === 1 && !availableToDropdow }"
         data-bs-toggle="dropdown">
      <div class="form-select__text">{{ value ? items[value] : name }}</div>
    </div>
    <DropdownList :is-selector="true" v-if="items">
      <template #menu>
        <li v-for="[name, value] in Object.entries(items)" :key="name">
          <button class="btn btn-dropdown" @click.prevent="handleChange(name)">{{value}}</button>
        </li>
      </template>
    </DropdownList>
  </div>
</template>

<script>
import DropdownList from "@/components/reusable/DropdownList.vue";

export default {
  props: {
    items: Object,
    value: String,
    name: String,
    availableToDropdow: Boolean,
  },

  methods: {
    handleChange(value) {
      this.$emit('update:value', value);
      this.$refs.selectElement.click();
    }
  },
  components: {
    DropdownList,
  },
}
</script>

<style scoped>
  .form-select-fixed {
    --bs-form-select-bg-img: none;
    pointer-events: none;
  }

  .form-select__nowrap-container {
    white-space: nowrap;
    overflow-x: hidden;
    border-color: var(--form-border-color);
    background-color: var(--block-bg-color);
    min-height: 39px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn-group:hover .form-select__nowrap-container {
    border-color: var(--primary-color);
  }

  .form-select__text {
    padding-right: 10px;
    overflow-x: hidden;
    color: var(--main-text-color);
  }
</style>