<template>
  <div class="py-2">
    <SelectOptions class="w-100" name="Выберите режим распознавания изображения"
                   :items="vision_detail"
                   :value="options.detail"
                   @update:value="value => options.detail = value"/>
  </div>
</template>

<script>
  import {visionDetailOptions} from "@/data/options";
  import SelectOptions from "@/components/reusable/SelectOptions.vue";

  export default {
    components: {SelectOptions},

    data() {
      return {
        vision_detail: visionDetailOptions,
        options: {
          detail: null,
        }

      }
    },
  }
</script>

<style scoped>

</style>