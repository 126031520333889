<template>
  <div class="d-flex flex-wrap gap-2">
    <button class="btn btn-outline-primary" v-for="interaction in interactions" :key="interaction"
            @click.prevent="interact(interaction)" :title="interaction.title">
      {{ interaction.name }}
    </button>
  </div>
</template>

<script>
import {setInteractions} from "@/data/interactions";
import {scrollToBottom} from "@/helpers/interface_helpers";

export default {
    data() {
      return {
        interactions: setInteractions,
      }
    },

    props: {
        messageId: Number,
        chatId: Number,
    },

    computed: {
        lastMessageId() {
          return this.$store.state.messages[this.chatId].at(-1).id;
        },
    },

    methods: {
        async interact(interaction) {
            this.$store.commit('setLoading', {value: true, id: this.chatId});
            const message = {
                interaction: {
                    reply_to: this.messageId,
                    action_name: interaction.interaction,
                },
                parent_id: this.lastMessageId,
            };
            if (interaction.value) {
                message.interaction.action_param = interaction.value;
            }

            const newMessage = await this.$store.dispatch('sendMessage', {id: this.chatId, message});
            await this.$store.commit('addChatMessage', {message: newMessage, id: this.chatId});

          await scrollToBottom();
        }
    }
}
</script>

<style scoped>

</style>