<template>
  <div class="row pb-2">
    <div class="form-check py-3">
      <input class="form-check-input" type="checkbox" v-model="options.async_mode" id="async_mode">
      <label class="form-check-label ms-1" for="async_mode">
        Асинхронный режим работы
      </label>
    </div>

    <div class="col-6 mt-2">
      <div class="options_range-label">
        <label class="form-label" for="max_tokens">Максимальное число токенов в ответе</label>
        <span>{{ options.max_tokens }}</span>
      </div>
      <input class="form-range" type="range" id="max_tokens" name="max_tokens" min="100" max="2000" step="1"
             v-model="options.max_tokens"/>
    </div>

    <div class="col-6 mt-2">
      <div class="options_range-label">
        <label class="form-label" for="temperature">Температура</label>
        <span>{{ options.temperature }}</span>
      </div>
      <input class="form-range" type="range" id="temperature" name="temperature" min="0" max="1" step="0.1"
             v-model="options.temperature"/>
    </div>
  </div>
</template>


<script>
  export default {
    data() {
      return {
        options: {
          max_tokens: null,
          temperature: null,
          async_mode: false,
        },
      };

    },

  }
</script>


<style scoped>
  .options_range-label {
    display: flex;
    justify-content: space-between;
  }
</style>