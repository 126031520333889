<template>
  <div class="audio-player">
    <button class="audio-player__btn" @click="togglePlay" :class="{ play: isPaused }">
      <PlayerPlayIcon v-if="isPaused" />
      <PlayerPauseIcon v-else />
    </button>
    <span class="audio-player__time">{{ formattedCurrentTime }}</span>
    <div class="audio-player__progress-container" @click="seek($event)">
      <div :style="{ width: progressPercent + '%' }" class="audio-player__progress"></div>
    </div>
    <span class="audio-player__time">{{ formattedDuration }}</span>
  </div>
</template>

<script>
import PlayerPauseIcon from "@/components/icons/PlayerPauseIcon.vue";
import PlayerPlayIcon from "@/components/icons/PlayerPlayIcon.vue";

export default {
  components: {PlayerPlayIcon, PlayerPauseIcon},
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      audio: null,
      currentTime: 0,
      duration: 0,
      isPaused: true
    };
  },
  computed: {
    formattedCurrentTime() {
      const minutes = Math.floor(this.currentTime / 60);
      const seconds = Math.floor(this.currentTime % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    formattedDuration() {
      const remainingTime = this.duration - this.currentTime;
      const minutes = Math.floor(remainingTime / 60);
      const seconds = Math.floor(remainingTime % 60);
      return `-${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    progressPercent() {
      return (this.currentTime / this.duration) * 100;
    }
  },
  methods: {
    togglePlay() {
      if (this.isPaused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    updateTime() {
      this.currentTime = this.audio.currentTime;
      this.duration = this.audio.duration;
    },
    seek(event) {
      const progressContainer = this.$el.querySelector('.audio-player__progress-container');
      const clickPos = event.offsetX / progressContainer.offsetWidth;
      this.audio.currentTime = clickPos * this.audio.duration;
    }
  },
  mounted() {
    this.audio = new Audio(this.src);
    this.audio.addEventListener('timeupdate', this.updateTime);
    this.audio.addEventListener('play', () => this.isPaused = false);
    this.audio.addEventListener('pause', () => this.isPaused = true);
  },

  beforeUnmount() {
    this.audio.removeEventListener('timeupdate', this.updateTime);
    this.audio.removeEventListener('play', () => this.isPaused = false);
    this.audio.removeEventListener('pause', () => this.isPaused = true);
    this.audio.pause();
  }
};
</script>

<style scoped>
.audio-player {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 9px 16px;
  border-radius: 6px;
  width: 300px;
}

.audio-player__btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 0;

}

.audio-player__btn.play {
  color: #666;
}

.audio-player__time {
  font-family: Arial, sans-serif;
  color: #666;
  margin: 0 10px;
}

.audio-player__progress-container {
  position: relative;
  flex: 1;
  height: 8px;
  background-color: #eee;
  border-radius: 8px;
  overflow: hidden;
}

.audio-player__progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--primary-color);
  width: 0;
}
</style>