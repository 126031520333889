<template>
  <div class="d-flex align-items-center mt-3" v-if="variants" :class="{ 'switcher_disabled': loading }">
    <ArrowButtonLeftIcon class="me-2" role="button" @click="switchToPrevBranch" />
    <span> {{ `${variants.current + 1}/${variants.count}` }}</span>
    <ArrowButtonRightIcon class="ms-2" role="button" @click="switchToNextBranch" />
  </div>
</template>

<script>
  import ArrowButtonLeftIcon from "@/components/icons/ArrowButtonLeftIcon.vue";
  import ArrowButtonRightIcon from "@/components/icons/ArrowButtonRightIcon.vue";

  export default {
    components: {ArrowButtonRightIcon, ArrowButtonLeftIcon},
    props: {
      chatId: Number,
      messageId: Number,
      parentId: Number,
    },

    computed: {
      variants() {
        if (this.parentId === null) return false;

        const variants = this.$store.state.variants[this.chatId];
        if (variants) {
          const currentVariants = variants.find(v => {
            return Number(v.parentId) === Number(this.parentId);
          });
          if (!currentVariants) return false;
          if (currentVariants.ids.length < 2) return false;

          const  currentVariant = currentVariants.ids.find(el => el === currentVariants.current);
          const currentIndex = currentVariants.ids.indexOf(currentVariant)
          return {
            current:  currentIndex === -1 ? currentVariants.ids.length - 1 : currentIndex,
            count: currentVariants.ids.length,
            ids: currentVariants.ids,
            variantsIndex: variants.indexOf(currentVariants),
          };
        }
      },

      loading() {
        return this.$store.state.loading[this.chatId];
      },
    },

    methods: {
      switchToNextBranch() {
        if (this.variants.current < this.variants.count - 1) {
          this.$store.dispatch('switchBranch', {
            chatId: this.chatId,
            messageId: this.messageId,
            newMessageId: this.variants.ids[this.variants.current + 1],
            variantsIndex: this.variants.variantsIndex,
          });
        }
      },

      switchToPrevBranch() {
        if (this.variants.current > 0) {
          this.$store.dispatch('switchBranch', {
            chatId: this.chatId,
            messageId: this.messageId,
            newMessageId: this.variants.ids[this.variants.current - 1],
            variantsIndex: this.variants.variantsIndex,
          });
        }
      },
    },
  }
</script>

<style scoped>
  .switcher_disabled {
    pointer-events:none;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>