<template>
  <div class="d-flex flex-wrap gap-3 py-2">
    <div class="w-100">
      <label class=" form-label" for="assistant_name">Название</label>
      <input class="form-control" id="assistant_name"
             v-model.lazy="name"
             @change="saveSettings"
             @keydown.enter.stop
             placeholder="Введите название" />
    </div>

    <div class="w-100">
      <label class=" form-label" for="assistant_instruction">Инструкция</label>
      <textarea class="form-control" id="assistant_instruction"
                v-model.lazy="instruction"
                @change="saveSettings"
                @keydown.enter.stop
                placeholder="Введите текст"></textarea>
    </div>

    <div class="w-100">
      <label class="form-label w-100" for="versions">Модель</label>
      <SelectOptions class="w-30" :items="versions"
                     @click="handleModelChange"
                     name="Выберите модель"
                     :value="version"
                     @update:value="value => handleUpdate(value)" />
    </div>

    <div class="d-flex flex-wrap gap-2 w-100">
      <div class="form-check form-switch switch-fixed-width d-flex align-items-center gap-2 w-100">
        <input class="form-check-input" type="checkbox" role="switch"
               id="code_interpreter"
               v-model="code_interpreter"
               @change="saveSettings" >
        <label class="form-check-label" for="code_interpreter">Интерпретатор кода</label>
      </div>
      <div>
        <button class="btn btn-outline-primary"
                data-bs-target="#code_interpreter_files"
                data-bs-toggle="modal" @click.stop.prevent >Выбрать файл</button>
        <FilesAttachments ref="code_interpreter" modal-id="code_interpreter_files"
                          :tabs="fileTabs.code_interpreter"
                          :is-cancel-button-disabled="true"
                          @attach-files="attachFiles"/>
        <span class="ms-3 small">Кол-во загруженных файлов: {{ $store.state.files.code_interpreter.length }}</span>
      </div>
    </div>

    <div class="d-flex flex-wrap gap-2 w-100">
      <div class="form-check form-switch switch-fixed-width d-flex align-items-center gap-2 w-100">
        <input class="form-check-input" type="checkbox" role="switch"
               id="file_search"
               v-model="file_search"
               :disabled="!availableForModel"
               @change="saveSettings" >
        <label class="form-check-label" for="file_search">Поиск файлов</label>
      </div>
      <div>
        <button class="btn btn-outline-primary"
                data-bs-target="#file_search_files"
                :disabled="!availableForModel"
                data-bs-toggle="modal" @click.stop.prevent >Выбрать файл</button>
        <FilesAttachments v-if="availableForModel" ref="file_search"
                          modal-id="file_search_files"
                          :tabs="fileTabs.file_search"
                          :is-message-attachments="false"
                          :is-cancel-button-disabled="true"
                          @attach-files="attachFiles"/>
        <span class="ms-3 small">Кол-во загруженных файлов: {{ $store.state.files.file_search.length }}</span>
      </div>
    </div>
  </div>

</template>

<script>
import {codeInterpreterMimeTypes, fileSearchMimeTypes} from "@/data/mime_types";
import {fileSearchAvailableModels} from "@/data/file_search_available_models";
import SelectOptions from "@/components/reusable/SelectOptions.vue";
import FilesAttachments from "@/components/messages/FilesAttachments.vue";
import {fileTabsForChat} from "@/data/file_tabs";

export default {
  components: {FilesAttachments, SelectOptions},
  props: {
    chat: Object,
  },

  data() {
    return {
      file_search: Number(this.chat.properties.assistant_file_search) === 1 && fileSearchAvailableModels.includes(this.chat.version.slug),
      code_interpreter: Number(this.chat.properties.assistant_code_interpreter) === 1,
      instruction: this.chat.properties.assistant_instruction,
      name: this.chat.properties.assistant_name,
      version: this.chat.version.slug,

      isSaved: false,

      fileSearchMimeTypes: fileSearchMimeTypes,

      codeInterpreterMimeTypes: codeInterpreterMimeTypes,

      fileTabs: fileTabsForChat,
    };
  },

  computed: {
    versions() {
      return this.$store.getters.aisVersionsBySlug(this.chat.ai.slug);
    },

    availableForModel() {
      return fileSearchAvailableModels.includes(this.version);
    },
  },

  beforeMount() {
    this.$store.commit('addFiles', {
      type: 'code_interpreter',
      files: this.chat.media.filter(({collection_name}) => collection_name === 'code_interpreter'),
    });
    this.$store.commit('addFiles', {
      type: 'file_search',
      files: this.chat.media.filter(({collection_name}) => collection_name === 'file_search'),
    });
  },

  methods: {
    async saveSettings() {
      if (await this.$store.state.api.addPropertiesToChat(this.chat.id, {
        assistant_file_search: Number(this.file_search),
        assistant_code_interpreter: Number(this.code_interpreter),
        assistant_instruction: this.instruction,
        assistant_name: this.name,
        version: this.version,
      })) {
        this.isSaved = true;
        setTimeout(() => this.isSaved = false, 3000);
      }
    },

    handleModelChange() {
      this.file_search = Number(this.chat.properties.assistant_file_search) === 1 && fileSearchAvailableModels.includes(this.version);
    },

    handleUpdate(value) {
      this.version = value;
      this.$store.commit('resetChatAiVersion', {id: this.chat.id, slug: value})
      this.saveSettings();
    },

    async attachFiles() {
      await this.$store.state.api.addPropertiesToChat(this.chat.id);
    },

  }
}
</script>

<style scoped>
.switch-fixed-width {
  width: 15%;
  min-width: fit-content;
}

#assistant_instruction {
  min-height: 140px;
}

.w-30 {
  width: 30%;
}
</style>