<template>
  <nav class="app-navbar">
    <button v-if="$route.name !== 'PublicChat'" class="btn btn-outline-primary" @click="navigateToSupport">
      Связаться с поддержкой
    </button>

    <AppTitle v-else class="app-navbar-title" />

    <div class="app-navbar__settings">
      <router-link v-if="$route.name !== 'PublicChat'" to="/finances" class="app-navbar__balance">
        <BalanceTitle :balance="balance" />
      </router-link>

      <ThemeSwitchIcon  role="button" @click="switchTheme" />

      <div class="dropdown" v-if="isAuthorized">
        <div class="d-flex align-items-center gap-1">
          <div class="d-flex gap-1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="app-navbar__text" v-html="name ? name : username"></span>
            <ArrowDownIcon />
          </div>

          <DropdownList>
            <template #menu>
              <li>
                <router-link to="/favourites" class="btn btn-dropdown">
                  <FavouriteIcon size="22"/>
                  <span class="mx-2">Избранное</span>
                </router-link>
              </li>
              <li>
                <router-link to="/account" class="btn btn-dropdown">
                  <SettingsIcon />
                  <span class="mx-2">Настройки</span>
                </router-link>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <a class="btn btn-dropdown app-navbar__logout-button" href="javascript:" @click="logout" role="button">
                  <LogoutIcon />
                  <span class="mx-2">Выйти</span>
                </a>
              </li>
            </template>
          </DropdownList>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import DropdownList from "@/components/reusable/DropdownList.vue";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";
import CoinIcon from "@/components/icons/CoinIcon.vue";
import FavouriteIcon from "@/components/icons/FavouriteIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import LogoutIcon from "@/components/icons/LogoutIcon.vue";
import AppTitle from "@/components/icons/AppTitle.vue";
import BalanceTitle from "@/components/icons/BalanceTitle.vue";
import ThemeSwitchIcon from "@/components/icons/ThemeSwitchIcon.vue";

export default {
  components: {
    ThemeSwitchIcon,
    BalanceTitle, AppTitle, LogoutIcon, SettingsIcon, FavouriteIcon, CoinIcon, ArrowDownIcon, DropdownList},

  computed: {
    username() {
      return this.$store.state.username;
    },

    name() {
      return this.$store.state.name;
    },

    balance() {
      return this.$store.state.balance;
    },

    isAuthorized() {
      return this.$store.state.isAuthorized;
    },
  },

  methods: {
    async logout(e) {
      e.preventDefault();
      if (await this.$store.state.api.logout()) {
        await localStorage.removeItem('neyro_username');
        await this.$store.dispatch('cleanState');
        await this.$router.push({name: 'Login'});
      }
    },

    switchTheme() {
      const currentTheme = this.$store.state.appTheme;
      if (currentTheme === 'light') {
        this.$store.commit('setAppTheme', 'dark');
      }
      if (currentTheme === 'dark') {
        this.$store.commit('setAppTheme', 'light');
      }
    },

    navigateToSupport() {
      window.open('https://t.me/NEYRO_Support_bot');
    }
  },
}
</script>

<style scoped>
  .app-navbar {
    display: flex;
    height: 62px;
    padding: 12px 24px;
    background-color: var(--block-bg-color);
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px #A5A3AE4D;
    user-select: none;
  }

  .app-navbar__text {
    font-size: 18px;
  }

  .app-navbar__settings {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 16px;
  }

  .app-navbar__balance {
    display: flex;
    gap: 16px;
    align-items: center;
    text-decoration: none;
    color: inherit;
    margin-right: 50px;
  }

  .app-navbar__balance:hover {
    color: var(--primary-color);
  }

  .app-navbar-title {
    margin: 10px 0 8px 0;
  }

  .app-navbar__logout-button {
    width: 230px;
  }
</style>