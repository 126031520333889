<script>
  export default {
    props: {
      title: String,
    }
  }
</script>

<template>
  <div class="my-4 w-100">
    <span class="title-text fs-3" v-text="title"></span>
  </div>
</template>

<style scoped>
  .title-text {
    color: var(--main-header-color);
  }
</style>