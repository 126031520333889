<template>
  <div>
    <ul class="dropdown-menu dropdown-menu-end" :class="{
      'w-100': isSelector,
      'select-shadow': isSelector,
    }">
      <slot name="menu"></slot>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      isSelector: Boolean
    }
  }
</script>

<style scoped>
  .dropdown-menu {
    border: none;
    width: fit-content;
    padding: 8px;
    background-color: var(--block-bg-color);
    box-shadow: var(--main-shadow-color);
  }

  .select-shadow {
    box-shadow: 0px 10px 20px 0px #A5A3AE66;
  }
</style>