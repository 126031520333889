<template>
  <div class="dialog-window-navbar">
    <div class="d-flex flex-nowrap align-items-center">
      <AiDynamicIcon size_class="ai-icon" :ai_logo="chat.ai.logo" />
      <div>
        <div class="d-flex gap-1 align-items-center">
          <small>{{ chat.ai.name }} ({{ chat.version.name }})</small>
          <ChatInfo class="" :messages="messages" :chatCost="chat.cost" :chatId="chat.id"/>
        </div>
        <span class="fs-5 me-2">{{ chat.name }}</span>
      </div>
    </div>

    <div class="d-flex gap-2">
      <ShareIcon
        role="button"
        @click="generateLink"
        :data-bs-target="'#chatLink' + chat.id"
        data-bs-toggle="modal" />

      <DeleteIcon
        role="button"
        size="22"
        color="danger"
        data-bs-target="#deleteChat"
        data-bs-toggle="modal" />
    </div>

    <Modal id="deleteChat" modal-name="Удалить чат" :is-footer-shown="true">
      <template #body>
        <div>
          При удалении чата стирается вся информация, введеная и полученная вами ранее.
          <br>
          Вы уверены, что хотите удалить чат?
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent>Отменить</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click.prevent="deleteChat">Удалить</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import AiDynamicIcon from "@/components/icons/AiDynamicIcon.vue";
  import ChatInfo from "@/components/chats/ChatInfo.vue";
  import DeleteIcon from "@/components/icons/DeleteIcon.vue";
  import ShareIcon from "@/components/icons/ShareIcon.vue";
  import Modal from "@/components/reusable/Modal.vue";

  export default {
    components: {
      Modal,
      ShareIcon,
      DeleteIcon,
      ChatInfo,
      AiDynamicIcon
    },

    props: {
      chat: Object,
      messages: Object,
    },

    methods: {
      async deleteChat() {
         if (this.$store.dispatch('deleteChat', {id: this.chat.id})) {
           this.$router.push({ path: '/chats/create' });
         }
      },

      generateLink() {
        if (!this.chat.public_link_token) {
          this.$store.dispatch('generateToken', {id: this.chat.id});
        }
      },

    }
  }
</script>

<style scoped>
  .dialog-window-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-bottom: 1px solid var(--border-color);
  }
</style>