<template>
    <div class="row pb-2">
        <div class="col-3">
          <SelectOptions class="w-100" name="Качество изображения"
                         :items="qualities"
                         :value="options.quality"
                         @update:value="value => options.quality = value" />
        </div>

        <div class="col-3" v-if="chat.version.slug === '5.1' || chat.version.slug === '5.2'">
          <SelectOptions class="w-100" name="Стиль изображения"
                         :available-to-dropdow="true"
                         :items="styles.default"
                         :value="options.style"
                         @update:value="value => options.style = value" />
        </div>

        <div class="col-3" v-if="chat.version.slug === 'niji'">
          <SelectOptions class="w-100" name="Стиль изображения"
                         :items="styles.niji"
                         :value="options.style"
                         @update:value="value => options.style = value" />
        </div>

        <div class="col-6">
            <label class="visually-hidden" for="no">Не добавлять в изображение</label>
            <input type="text" class="form-control" id="no" v-model="options.no"
                   placeholder="Исключаемые объекты (через запятую)">
        </div>

        <div class="col-6 mt-2">
            <label class="form-label" for="stop">Остановка процесса генерации</label>
            <input class="form-range" type="range" id="stop" name="stop" min="1" max="100" step="1"
                   v-model="options.stop"/>
        </div>

        <div class="col-6 mt-2">
            <label class="form-label" for="stylize">Степень артистичности</label>
            <input class="form-range" type="range" id="stylize" name="stylize" min="1" max="1000" step="1"
                   v-model="options.stylize"/>
        </div>
    </div>
</template>

<script>
import SelectOptions from "@/components/reusable/SelectOptions.vue";

export default {
  components: {SelectOptions},
    data() {
        return {
            qualities: { '.25': '.25', '.5': '.5', '1': '1' },
            styles: {
              default: {
                raw: 'Сырой стиль',
              },
              niji: {
                cute: 'Милый',
                scenic: 'Живописный',
                expressive: 'Выразителный',
              },
            },
            options: {
              quality: null,
              style: null,
              no: null,
            },
        };
    },

    props: {
        chat: Object
    },

    methods: {

    }
}
</script>

<style scoped>
input[type="range"]::-webkit-slider-thumb {
    background: var(--bs-gray-600);
}
</style>